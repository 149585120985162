<template>
  <div class="footer" :class="!isEnglish ? 'footer__japanese' : ''">
        <div class="footer__container">
            <div class="footer__logo-container">
                <a href="https://github.com/dpars34" target="_blank" rel="noopener noreferrer">
                    <svg width="100%" height="100%" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M20 0C8.95 0 0 9.17638 0 20.5059C0 29.5797 5.725 37.2438 13.675 39.9608C14.675 40.1403 15.05 39.5251 15.05 38.9868C15.05 38.4998 15.025 36.8849 15.025 35.1676C10 36.116 8.7 33.9116 8.3 32.7581C8.075 32.1686 7.1 30.3487 6.25 29.8617C5.55 29.4772 4.55 28.5288 6.225 28.5032C7.8 28.4775 8.925 29.9898 9.3 30.605C11.1 33.7065 13.975 32.835 15.125 32.2968C15.3 30.9639 15.825 30.0667 16.4 29.5541C11.95 29.0414 7.3 27.2728 7.3 19.4293C7.3 17.1993 8.075 15.3538 9.35 13.9184C9.15 13.4057 8.45 11.3039 9.55 8.48431C9.55 8.48431 11.225 7.94603 15.05 10.5862C16.65 10.1248 18.35 9.89408 20.05 9.89408C21.75 9.89408 23.45 10.1248 25.05 10.5862C28.875 7.92039 30.55 8.48431 30.55 8.48431C31.65 11.3039 30.95 13.4057 30.75 13.9184C32.025 15.3538 32.8 17.1737 32.8 19.4293C32.8 27.2984 28.125 29.0414 23.675 29.5541C24.4 30.1949 25.025 31.4253 25.025 33.3477C25.025 36.0903 25 38.2947 25 38.9868C25 39.5251 25.375 40.1659 26.375 39.9608C30.3453 38.5865 33.7953 35.9702 36.2394 32.4802C38.6836 28.9903 39.9988 24.8023 40 20.5059C40 9.17638 31.05 0 20 0Z" :fill="isEnglish ? '#e1e1e1' : '#232323'"/>
                    </svg>
                </a>
                <a href="https://www.linkedin.com/in/daniel-parsons-b2491420a" target="_blank" rel="noopener noreferrer">
                    <svg width="100%" height="100%" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.94654e-07 4.31475C4.94654e-07 3.42779 0.352084 2.57716 0.978796 1.94998C1.60551 1.32281 2.45551 0.970463 3.34182 0.970463H36.6545C37.0938 0.969746 37.5288 1.05573 37.9348 1.2235C38.3408 1.39127 38.7097 1.63752 39.0204 1.94816C39.3312 2.2588 39.5777 2.62773 39.7458 3.03382C39.9138 3.43992 40.0002 3.8752 40 4.31475V37.6521C40.0005 38.0917 39.9143 38.5271 39.7465 38.9334C39.5786 39.3397 39.3324 39.7089 39.0218 40.0198C38.7113 40.3308 38.3425 40.5774 37.9366 40.7456C37.5307 40.9138 37.0957 41.0002 36.6564 41H3.34182C2.90281 41 2.46811 40.9134 2.06254 40.7453C1.65697 40.5771 1.28849 40.3306 0.978153 40.0198C0.667813 39.7091 0.421694 39.3402 0.253859 38.9343C0.0860241 38.5283 -0.000238356 38.0932 4.94654e-07 37.6539V4.31475ZM15.8327 16.2326H21.2491V18.9546C22.0309 17.3899 24.0309 15.9815 27.0364 15.9815C32.7982 15.9815 34.1636 19.0984 34.1636 24.8172V35.4104H28.3327V26.1199C28.3327 22.863 27.5509 21.0253 25.5655 21.0253C22.8109 21.0253 21.6655 23.0067 21.6655 26.1199V35.4104H15.8327V16.2326ZM5.83273 35.1611H11.6655V15.9815H5.83273V35.1593V35.1611ZM12.5 9.72601C12.511 10.2258 12.4221 10.7227 12.2386 11.1877C12.0551 11.6526 11.7806 12.0762 11.4313 12.4335C11.082 12.7909 10.6649 13.0748 10.2045 13.2686C9.74402 13.4625 9.24953 13.5623 8.75 13.5623C8.25047 13.5623 7.75598 13.4625 7.29554 13.2686C6.83509 13.0748 6.41798 12.7909 6.06867 12.4335C5.71936 12.0762 5.4449 11.6526 5.26138 11.1877C5.07787 10.7227 4.989 10.2258 5 9.72601C5.02159 8.74503 5.42615 7.8115 6.12706 7.12534C6.82797 6.43919 7.7695 6.05497 8.75 6.05497C9.7305 6.05497 10.672 6.43919 11.3729 7.12534C12.0738 7.8115 12.4784 8.74503 12.5 9.72601V9.72601Z" :fill="isEnglish ? '#e1e1e1' : '#232323'"/>
                    </svg>
                </a>
                <!-- <a href="" target="_blank" rel="noopener noreferrer">
                    <svg width="100%" height="100%" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 0C8.95536 0 0 8.95536 0 20C0 31.0446 8.95536 40 20 40C31.0446 40 40 31.0446 40 20C40 8.95536 31.0446 0 20 0ZM29.6116 15.0759C29.625 15.2857 29.625 15.5045 29.625 15.7188C29.625 22.2723 24.6339 29.8214 15.5134 29.8214C12.7009 29.8214 10.0938 29.0045 7.89732 27.5982C8.29911 27.6429 8.68304 27.6607 9.09375 27.6607C11.4152 27.6607 13.5491 26.875 15.25 25.5446C13.0714 25.5 11.2411 24.0714 10.6161 22.1071C11.3795 22.2188 12.067 22.2188 12.8527 22.0179C11.7309 21.79 10.7227 21.1807 9.99918 20.2937C9.2757 19.4066 8.88164 18.2965 8.88393 17.1518V17.0893C9.54018 17.4598 10.3125 17.6875 11.1205 17.7188C10.4413 17.266 9.88419 16.6527 9.49871 15.9332C9.11323 15.2136 8.91127 14.4101 8.91071 13.5938C8.91071 12.6696 9.15179 11.8259 9.58482 11.0938C10.8299 12.6265 12.3836 13.8801 14.1449 14.7731C15.9062 15.666 17.8357 16.1784 19.808 16.2768C19.1071 12.9063 21.625 10.1786 24.6518 10.1786C26.0804 10.1786 27.3661 10.7768 28.2723 11.7411C29.3929 11.5313 30.4643 11.1116 31.4196 10.5491C31.0491 11.6964 30.2723 12.6652 29.2411 13.2768C30.2411 13.1696 31.2054 12.8929 32.0982 12.5045C31.4241 13.4955 30.5804 14.375 29.6116 15.0759V15.0759Z" :fill="isEnglish ? '#e1e1e1' : '#232323'"/>
                    </svg>
                </a> -->
            </div>
            <div class="footer__text-container">
                © Daniel Parsons 2023
            </div>
        </div>
  </div>
  
</template>

<script>
export default {
    name: 'Footer',
    props: {
        isEnglish: {
            type: Boolean,
            default: true
        }
    }
}
</script>

<style scoped>
.footer {
    border-top: solid 0.1rem #e1e1e1;
    padding: 1.6rem;
    color: #e1e1e1;
    margin: auto;
}

.footer__japanese {
    border-top: solid 0.1rem #232323;
    color: #232323;
}

.footer__container {
    max-width: calc(1440px - 20rem);
    margin: auto;
}

.footer__logo-container {
    display: flex;
    gap: 2.4rem;
}

.footer__logo-container a > svg {
    height: 4rem;
    width: 4rem;
}

.footer__text-container {
    font-size: 1.6rem;
    padding-top: 1.6rem;
}

@media screen and (min-width: 768px) {
    .footer__container { 
        padding: 0 10rem;
    }
}

</style>